.list-style-dash {
    list-style-type: none;
    li {
        position: relative;
        padding-left: 15px;
        &::before {
            visibility: hidden;
        }
        &::after {
            content: "-";
            text-indent: -5px;
            position: absolute;
            left: 0;
            top: 1px;
        }
    }
}
.list-style-dot {
    list-style-type: none;
    li {
        position: relative;
        padding-left: 15px;
        &::before {
            width: 6px;
            height: 6px;
            vertical-align: top;
            margin-top: rem-calc(10);
            margin-left: -1.25rem;
            margin-right: 0.75rem;
            background: $medium-gray;
        }
    }
}

