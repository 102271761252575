@mixin video-wrapper(){
    .video-container.widescreen{
        margin-top: 1rem;
        padding-bottom: rem-calc(270);
        iframe{
            max-width: rem-calc(480);
            max-height: rem-calc(270);
        }
    }
}
