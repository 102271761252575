.list-style-dash {
  list-style-type: none;
}
.list-style-dash li {
  position: relative;
  padding-left: 15px;
}
.list-style-dash li::before {
  visibility: hidden;
}
.list-style-dash li::after {
  content: "-";
  text-indent: -5px;
  position: absolute;
  left: 0;
  top: 1px;
}

.list-style-dot {
  list-style-type: none;
}
.list-style-dot li {
  position: relative;
  padding-left: 15px;
}
.list-style-dot li::before {
  width: 6px;
  height: 6px;
  vertical-align: top;
  margin-top: 0.625rem;
  margin-left: -1.25rem;
  margin-right: 0.75rem;
  background: #676d7f;
}

.callout.success {
  padding: 0.5rem 1rem 0.5rem 3.5rem;
  border-color: #00aa61;
  color: #333333;
}
.callout.success:before {
  content: url("../../images/icons/success.svg");
}
.callout.success.closeable {
  padding-right: 2.5rem;
}

.callout.error {
  padding: 0.5rem 1rem 0.5rem 3.5rem;
  border-color: #e12b1d;
  color: #333333;
}
.callout.error:before {
  content: url("../../images/icons/error.svg");
}
.callout.error.closeable {
  padding-right: 2.5rem;
}

.callout.alert {
  padding: 0.5rem 1rem 0.5rem 3.5rem;
  border-color: #e12b1d;
  color: #333333;
}
.callout.alert:before {
  content: url("../../images/icons/error.svg");
}
.callout.alert.closeable {
  padding-right: 2.5rem;
}

.cms-page-view .page-main p {
  margin-bottom: 2rem;
}
.cms-page-view .page-main ul li, .cms-page-view .page-main ol li {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.cms-page-view .page-title {
  margin-bottom: 2rem;
}

.video-container.widescreen {
  margin-top: 1rem;
  padding-bottom: 16.875rem;
}
.video-container.widescreen iframe {
  max-width: 30rem;
  max-height: 16.875rem;
}

.cms-section {
  margin: 4rem auto;
}

.fs-1r {
  font-size: 1rem;
}

.blue-txt {
  color: #0073c0;
}

.cat-title {
  font-size: 1.75rem;
  font-family: "Roboto Slab", Cambria, "Times New Roman", serif;
  color: #559a13;
}

.content-heading {
  font-size: 1.5rem;
  margin: 1rem 0;
  font-weight: 400;
  color: #e67921;
}

.list-spacing {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cms-table.bordered {
  border: 1px solid #ccc;
}

.content-sub-heading {
  font-size: 1.125rem;
  margin: 2rem 0 0.5rem;
}

.list-heading {
  font-size: 1.25rem;
  font-weight: 700;
  color: #0073c0;
  margin: 0 0 1rem -0.25rem;
}

.img-panel {
  border-radius: 0.3125rem;
  border: 1px solid #ccc;
  padding: 1rem 2rem;
  margin: 1rem 0.5rem;
  text-align: center;
}
.img-panel.column, .img-panel.columns {
  min-width: calc(50% - 1rem);
}
.img-panel img {
  filter: contrast(20%);
  max-height: 35px;
  width: auto;
}
.img-panel:hover {
  border-bottom-width: 1px;
  border-color: transparent;
  background: #0196e4;
}
.img-panel:hover img {
  filter: brightness(0) invert(1);
}

.aside-wrapper {
  background-color: #ffffff;
  padding: 2rem 1rem 1rem;
  margin-bottom: 2rem;
}
.aside-wrapper .content-sub-heading {
  margin-top: 0.5rem;
}

.cms-table-col {
  width: 25%;
}
.cms-table .ht-col {
  background: rgba(0, 170, 97, 0.25);
  font-weight: 700;
}
.cms-table tbody tr:nth-child(even) {
  background-color: transparent;
}
.cms-table tbody tr:nth-child(odd) {
  background-color: #f7f7f7;
}

.cms-page-view .help-card {
  padding: 0 0 0.5rem;
  text-align: left;
}
.cms-page-view .help-card-title {
  font-size: 1.25rem;
  margin: 0.5rem 0;
}
.cms-page-view .help-card-item {
  margin: 1rem 0;
}
.cms-page-view .help-card-item .default-icons {
  font-size: 1.375rem;
}
.cms-page-view .callout p {
  margin-bottom: 0.25rem;
}

.captcha-block {
  margin: 2rem 0 3rem;
}

.divider-cmy-swash {
  text-align: center;
  position: relative;
}
.divider-cmy-swash:after {
  content: url("../../images/icons/cmy-swash.svg");
  background: #ffffff;
  padding: 0 1rem;
  display: inline-block;
  left: 50%;
  margin-left: -25px;
  width: 50px;
  height: 36px;
  position: absolute;
  top: -8px;
}

.main-content a {
  word-break: break-all;
}

.table-overflow {
  overflow-y: auto;
}

@media screen and (min-width: 48em) {
  .aside-wrapper {
    padding: 2rem;
  }
  .cms-table-col {
    width: 20%;
  }
  .img-panel {
    padding: 2rem;
    margin: 1rem;
  }
  .img-panel.column, .img-panel.columns {
    min-width: 0;
  }
  .cms-page-view .video-container.center iframe {
    left: 50%;
    margin-left: -240px;
  }
}
@media screen and (min-width: 64em) {
  .main-content a {
    word-break: initial;
  }
  .table-overflow {
    overflow-y: hidden;
  }
  .aside-wrapper {
    margin-left: 2rem;
    margin-bottom: 0;
  }
}