@import '../../../styles/settings';
@import '../../../styles/cas/mixins';
@import 'block/list-style';

$svg-icons-path: '../../images/icons/';

@include callout(success);
@include callout(error);
@include callout(alert);


.cms-page-view {
    .page-main {
        p {
            margin-bottom: 2rem;
        }
        ul, ol {
            li {
                margin-top: 0.5rem;
                margin-bottom: 1rem;
            }
        }
    }
    .page-title {
        margin-bottom: 2rem;
    }
}

@include video-wrapper;
.cms-section {
    margin: 4rem auto;
}

.fs-1r {
    font-size: 1rem;
}

.blue-txt {
    color: get-color('blue');
}
.cat-title {
    font-size: rem-calc(28);
    font-family: $work-sans;
    color: get-color('secondary')
}

.content-heading {
    font-size: rem-calc(24);
    margin: 1rem 0;
    font-weight: 400;
    color: get-color('primary');
}

.list-spacing {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.cms-table {
    &.bordered {
        border: $global-border;
    }
}

.content-sub-heading {
    font-size: rem-calc(18);
    margin: 2rem 0 .5rem;
}

.list-heading {
    font-size: rem-calc(20);
    font-weight: 700;
    color: get-color('blue');
    margin: 0 0 1rem -0.25rem;
}

.img-panel {
    &.column, &.columns{
        min-width: calc(50% - 1rem);
    }
    border-radius: $global-radius;
    border: $global-border;
    padding: 1rem 2rem;
    margin: 1rem 0.5rem;
    text-align: center;

    img {
        filter: contrast(20%);
        max-height: 35px;
        width: auto;
    }

    &:hover {
        border-bottom-width: 1px;
        border-color: transparent;
        background: get-color('cyan');

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.aside-wrapper {
    background-color: $white;
    padding: 2rem 1rem 1rem;
    margin-bottom: 2rem;
    .content-sub-heading {
        margin-top: 0.5rem;
    }
}

.cms-table {
    &-col {
        width: 25%;
    }
    .ht-col {
        background: rgba(get-color('green'), 0.25);
        font-weight: 700;
    }
    tbody tr {
        &:nth-child(even) {
            background-color: transparent;
        }
        &:nth-child(odd) {
            background-color: $off-white;
        }
    }
}

.cms-page-view {
    .help-card {
        padding: 0 0 0.5rem;
        text-align: left;
        &-title {
            font-size: rem-calc(20);
            margin: 0.5rem 0;
        }
        &-item {
            margin: 1rem 0;
            .default-icons {
                font-size: rem-calc(22);
            }
        }
    }
    .callout {
        p {
            margin-bottom: 0.25rem;
        }
    }
}

.captcha-block {
    margin: 2rem 0 3rem;
}

.divider-cmy-swash {
    text-align: center;
    position: relative;
    &:after {
        content: url('../../images/icons/cmy-swash.svg');
        background: $white;
        padding: 0 1rem;
        display: inline-block;
        left: 50%;
        margin-left: -25px;
        width: 50px;
        height: 36px;
        position: absolute;
        top: -8px;
    }
}

.main-content a {
    word-break: break-all;
}
.table-overflow {
    overflow-y: auto;
}

@media screen and #{breakpoint(medium)} {
    .aside-wrapper {
        padding: 2rem;
    }
    .cms-table {
        &-col {
            width: 20%;
        }
    }

    .img-panel {
        &.column, &.columns {
            min-width: 0;
        }
        padding: 2rem;
        margin: 1rem;
    }
    .cms-page-view {
        .video-container {
            &.center {
                iframe {
                    left: 50%;
                    margin-left: -240px;
                }
            }
        }
    }
}

@media screen and #{breakpoint(large)} {
    .main-content a {
        word-break: initial;
    }
    .table-overflow {
        overflow-y: hidden;
    }
    .aside-wrapper {
        margin-left: 2rem;
        margin-bottom: 0;
    }
}

